import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class AccessMoreStrategiesSeeMoreModerateViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-moderate-hiring-flow';

  readonly STEPS = [
    {
      step: 0,
      component: 'StrategyInformationAndVideo',
    },
    {
      step: 1,
      component: 'StrategyComparisonPesosAndModerate',
    },
  ];

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  nextStep = async () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }
}
